// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        @finish="handleSubmit">
        <a-form-item name="shortName" ref="shortName">
          <template #label>
            <tooltip-label :title="$t('label.shortName')" :tooltip="$t('label.shortName.desc')"/>
          </template>
          <a-input
            v-model:value="form.shortName"
            :placeholder="$t('label.shortName.desc')"></a-input>
        </a-form-item>
        <a-form-item name="userName" ref="userName" :label="$t('label.username')">
          <a-input
            v-model:value="form.userName"
            :placeholder="$t('label.username')"></a-input>
        </a-form-item>
        <a-form-item name="password" ref="password" :label="$t('label.password')">
          <a-input-password
            v-model:value="form.password"
            :placeholder="$t('label.password')"></a-input-password>
        </a-form-item>
      </a-form>
      <div :span="24" class="action-button">
        <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
        <a-button :loading="checkFreeIpsLoading" type="primary" @click="handleSubmit" ref="submit">{{ $t('label.ok') }}</a-button>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { ref, reactive, toRaw } from 'vue'
import api2 from '@/wpApi/api2'
import wpapi from '@/wpApi/myApi'
import { api } from '@/api'
import TooltipLabel from '@/components/widgets/TooltipLabel'

export default {
  name: 'CreateSaasManager',
  components: {
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      checkFreeIpsLoading: false,
      checkNetwork: false,
      checkAccount: false,
      detachVgpuMessage: '',
      subAccountUuid: '',
      gpuLicenseKey: 0,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      // domainRegex: /^[A-Za-z0-9-.]{2,255}$/,
      shortNameRegex: /^[a-zA-Z0-9]{2,255}$/,
      userInfo: []
    }
  },
  async created () {
    this.initForm()
  },
  async mounted () {
    this.fetchResellerCustomerAccounts()
    await this.checkFreePublicIps()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        shortName: undefined,
        userName: undefined,
        password: undefined
      })
      this.rules = reactive({
        shortName: [{ required: true, message: `${this.$t('label.required')}` }, { validator: this.validateShortName }],
        userName: [{ required: true, message: `${this.$t('label.required')}` }],
        password: [{ required: true, message: `${this.$t('label.required')}` }]
      })
    },
    /* validateDomain (rule, value) {
      const errorMessage = this.$t('message.validate.special.characters.saasDomain')
      if (this.domainRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    }, */
    validateShortName (rule, value) {
      const errorMessage = this.$t('message.validate.special.characters.shortName')
      if (this.shortNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async checkFreePublicIps () {
      this.checkFreeIpsLoading = true
      await api('listPublicIpAddresses', { listall: true, networkid: '457765ac-ce51-40d0-bb40-903e077b9a3e', allocatedonly: false, forvirtualnetwork: false }).then(response => {
        if (response.listpublicipaddressesresponse.publicipaddress === undefined || response.listpublicipaddressesresponse.publicipaddress.length === 0) {
          this.$notification.error({
            message: this.$t('message.error.freeIp'),
            description: this.$t('message.error.freeIp.network'),
            duration: 0
          })
        } else { this.checkNetwork = true }
      }).catch(error => {
        console.log(error)
        this.checkNetwork = false
        this.checkFreeIpsLoading = false
      })
      await api('listPublicIpAddresses', { listall: true, state: 'Free', domainid: this.resource.domainid, account: this.resource.name }).then(response => {
        if (response.listpublicipaddressesresponse.publicipaddress === undefined || response.listpublicipaddressesresponse.publicipaddress.length === 0) {
          this.$notification.error({
            message: this.$t('message.error.freeIp'),
            description: this.$t('message.error.freeIp.account'),
            duration: 0
          })
        } else { this.checkAccount = true }
      }).catch(error => {
        console.log(error)
        this.checkAccount = false
        this.checkFreeIpsLoading = false
      })
      this.checkFreeIpsLoading = false
      // network id of öffentliche IPv4 in live: 457765ac-ce51-40d0-bb40-903e077b9a3e
    },
    fetchResellerCustomerAccounts () {
      if (this.resource.account && this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vm/')) {
        if (this.resource.account !== this.accountName) {
          wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
            this.subAccountUuid = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    closeAction () {
      this.$emit('close-action')
    },
    async handleSubmit (e) {
      if (this.loading) return
      await this.checkFreePublicIps()
      if (!this.checkAccount || !this.checkNetwork) return
      this.formRef.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.form)
        var createSaasManagerParams = new FormData()
        const userID = this.resource.user.filter(user => user.username === this.resource.name)[0].id || this.resource.user[0].id
        await api('getUserKeys', { id: userID }).then(async response => {
          if (Object.keys(response.getuserkeysresponse.userkeys).length === 0 && response.getuserkeysresponse.userkeys.constructor === Object) {
            await api('registerUserKeys', { id: userID }).then(async response => {
              if (response.registeruserkeysresponse.userkeys !== undefined) {
                this.userInfo = response.registeruserkeysresponse.userkeys
              }
            }).catch(error => {
              this.loading = false
              console.log(error)
            })
          } else {
            this.userInfo = response.getuserkeysresponse.userkeys
          }
        }).catch(error => {
          this.loading = false
          console.log(error)
        })
        createSaasManagerParams.append('accountuuid', this.resource.id)
        createSaasManagerParams.append('vmprefix', values.shortName)
        createSaasManagerParams.append('apikey', this.userInfo.apikey)
        createSaasManagerParams.append('secretkey', this.userInfo.secretkey)
        createSaasManagerParams.append('loginusername', values.userName)
        createSaasManagerParams.append('loginpassword', values.password)
        // TODO Implement addSaasManager API and logic
        await api2.createSaasManager(createSaasManagerParams).then(async response => {
          if (response.data && response.data !== 'error' && response.status === 200) {
            this.$notification.success({
              message: this.$t('message.success.create.saasManager')
            })
            this.loading = false
            this.closeAction()
            this.$emit('refresh-data')
          } else {
            this.$notification.error({
              message: this.$t('message.error.create.saasManager'),
              description: this.$t('message.error.create.saasManager2'),
              duration: 0
            })
            this.loading = false
            this.closeAction()
            this.$emit('refresh-data')
          }
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('message.error.create.saasManager'),
            description: this.$t('message.error.create.saasManager2'),
            duration: 0
          })
          this.loading = false
          this.closeAction()
          this.$emit('refresh-data')
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-layout {
  width: 80vw;
  @media (min-width: 700px) {
    width: 600px;
  }
}

.form {
  margin: 10px 0;
}
</style>
